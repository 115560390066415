import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'customPercent'
})
export class CustomPercentPipe implements PipeTransform {
	transform(valor: number, typePercent: string = '%'): string {
		if (!!valor?.toString()) {
			valor = parseFloat(valor + '');
			let formatedValue;
			switch (typePercent) {
				case '%': {
					const options = { style: 'decimal', minimumFractionDigits: 2 };
					const numberFormat = new Intl.NumberFormat('es-PE', options);
					formatedValue = numberFormat.format(valor*100);
					break;
				}
				case '%%': {
					const options = { style: 'decimal', minimumFractionDigits: 4 }; //minimumFractionDigits: 4
					const numberFormat = new Intl.NumberFormat('es-PE', options);
					formatedValue = numberFormat.format(valor * 1000);
					break;
				}
				case '%%%': {
					const options = { style: 'decimal', minimumFractionDigits: 8 };
					const numberFormat = new Intl.NumberFormat('es-PE', options);
					formatedValue = numberFormat.format(valor * 1000);
					break;
				}
				default: formatedValue = '0';
			}
			return `${Number(formatedValue.replace(/,/g, ''))}`;;
		}
		return '';
	}
}
