import { NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, } from 'ngx-ui-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { environment } from '../environments/environment';
import { metaReducers, reducers } from './_state/reducers/index';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './_state/effects/app.effects';
import { HttpClientModule } from '@angular/common/http';
import { ApiCoreService } from './shared/services/api-core.service';
import { MatrixService } from './shared/services/matrix.service';
import { MaterialModule } from './shared/modules/material.module';
import { SharedModule } from './shared/shared.module';
import { ThemeService } from './shared/services/theme.service';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: '#2b0d61',
	bgsOpacity: 0.5,
	bgsPosition: POSITION.bottomCenter,
	bgsSize: 40,
	bgsType: SPINNER.ballSpinClockwise, // background spinner type
	fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
	pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
	pbThickness: 3, // progress bar thickness
	text: '... CARGANDO',
	textColor: '#2b0d61',
	textPosition: 'center-center',
	pbColor: '#2b0d61',
	overlayColor: 'rgba(40,40,40,0.26)',
	fgsColor: '#2b0d61',
	blur: 3,
	delay: 0,
	fastFadeOut: true,
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelPropagation: true,
	suppressScrollX: true
};

@NgModule({
  declarations: [
	AppComponent
  ],
  imports: [
	BrowserModule,
	HttpClientModule,
	AppRoutingModule,
	PerfectScrollbarModule,
	BrowserAnimationsModule,
	EffectsModule.forRoot([AppEffects]),
	StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
	StoreModule.forRoot(reducers, { metaReducers }),
	SharedModule,
	MaterialModule,
	NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
	  !environment.production ? StoreDevtoolsModule.instrument() : []
	],
	providers: [
		ApiCoreService,
		MatrixService,
		ThemeService,
		{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },],
	bootstrap: [AppComponent]
})
export class AppModule { }
