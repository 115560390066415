import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minPremium'
})
export class MinPremiumPipe implements PipeTransform {
  transform(value: any, paymentPeriod: string = '1', type: string = '*'): string {
		if (value) {
			value = parseFloat(value + '');
			let op: number;
			switch (paymentPeriod) {
				case '1': op = 12;break;
				case '2':	op = 6;break;
				case '3': op = 4;break;
				case '4': op = 2;break;
				case '5':	op = 1;break;
				case '6': op = 24;break;
				default: op = 1;
			}
			return (value === '0') ? '' : `${Number(type==='*' ? (value*op) : (value/op))}`;
		}
		return '';
	}
}
