import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalFormat'
})
export class CapitalFormatPipe implements PipeTransform {

  transform(value: number | string ): string {
	  if (!!(value?.toString())) {
		  const options = { style: 'decimal' };
		  const numberFormat = new Intl.NumberFormat('es-PE', options);
		  return numberFormat.format(parseFloat(value + ''));
	  }
	  return '';
  }

}
