export const environment = {
	production: false,
	project_risk: 'Vida Crédito',
  risk_id: '71',
  product_id: '2',
  module_id: '1',
	keyCloakUrl: 'https://protecta.devmente.io:8443/auth',
	coreAPIName: 'CoreAPI',
	acprAPIName: 'acprAPI',
	aws: {
		aws_project_region: 'us-east-1',
		aws_cognito_identity_pool_id: 'us-east-1:594902652773',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_DsivwzTyk',
    aws_user_pools_web_client_id: '7vclcsal8kvtb5hf8uhe0tb94r',
    cookieStorage: {
      domain: '.ofvi.dev.protectasecuritycloud.pe',
      path: '/',
      secure: true
    },
		oauth: {}
	},
	aws_appsync_api_core: 'https://api.core.stg.protectasecuritycloud.pe/graphql',
	aws_appsync_api_core_authentication_value: 'da2-fcv7m7ecmbhxlfuz72lw2zx7ce',
/* 	aws_appsync_matrix: 'https://matrix-api.tcds.dev.protectasecuritycloud.pe/graphql',
	aws_appsync_matrix_authentication_value: 'da2-wzz2bm55p5fhppjudc55lqmgue', */
	aws_appsync_matrix: 'https://matrix-api.tcds.qas.protectasecuritycloud.pe/graphql',
	aws_appsync_matrix_authentication_value: 'da2-frytn6gjwnht3ndt6fvnjncypq',
	aws_appsync_region: 'us-east-1',
	aws_appsync_authentication_types: 'API_KEY',
	login: 'https://login.ofvi.dev.protectasecuritycloud.pe/'
};
