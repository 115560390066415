import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./views/dashboard/dashboard.module').then(dashboard => dashboard.DashboardModule),
		data: { title: 'Inicio', breadcrumb: 'INICIO' }
	},
	 {
		path: 'parameter',
		loadChildren: () => import('./views/parameter/parameter.module').then(parameter => parameter.ParameterModule),
		data: { title: 'Parametro', breadcrumb: 'PARAMETRO' }
	},
	{
		path: 'fee',
		loadChildren: () => import('./views/fee/fee.module').then(fee => fee.FeeModule),
		data: { title: 'Tarifario', breadcrumb: 'TARIFARIO' }
	} ,
	{
		path: '**',
		redirectTo: 'dashboard'
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
